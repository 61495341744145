/* eslint-disable no-promise-executor-return */

import { get, deleteRequest, post } from '@osrdata/app_core/dist/requests'
import { signal } from '@preact/signals-react'
import terms from 'assets/terms'
import { ToastLevel, ToastSignal } from 'components'
import moment from 'moment'
import { GedExport, PresignedUrl, Request } from 'types'
import { catchable } from 'utils'

export const RequestSignal = signal<Request[]>([])
export const GedExportSignal = signal<GedExport>({})

export const deleteRequests = async (id: string) => {
  const [error] = await catchable(() => deleteRequest(`/miki/requests/${id}`), true)
  if (error) return
  ToastSignal.value = {
    severity: ToastLevel.SUCCESS,
    message: terms.Pages.Home.requests.deleted,
  }
  RequestSignal.value = RequestSignal.value.filter(request => request.id !== id)
}

export const getRequests = async () => {
  const [error, response] = await catchable(() => get<Request[]>('/miki/requests'), true)
  if (error) return
  RequestSignal.value = response
}

export const getRequest = async (id: string) => {
  const [error, response] = await catchable(() => get<Request>(`/miki/requests/${id}`), true)
  if (error) return null

  RequestSignal.value = RequestSignal.value.map(request => (request.id === id ? response : request))
  return response
}

export const postRequest = async (name: string) => {
  const [error, response] = await catchable(() => post<Request>('/miki/requests', {
    name,
  }), true)
  if (error) return null
  return response
}

export const getPresignedUrl = async (request: Request, file: File, abortSignal: AbortSignal) => {
  const [error, response] = await catchable(() => post<PresignedUrl>(
    `/miki/requests/${request.id}/documents`,
    { document_path: file.name },
    { signal: abortSignal },
  ))
  if (error) return null
  return response
}

export const validateRequest = async (request: Request) => {
  await catchable(() => post(`/miki/requests/${request.id}/upload-done`, {}), true)
  await getRequests()
}

export const getExcel = (request: Request, getLast: boolean) => async () => {
  try {
    const response = await get(
      `/miki/requests/${request.id}/download${getLast ? '-last-' : '-'}ep`,
      null,
      null,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      },
    )

    const { filename, body } = response

    const byteCharacters = atob(body)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.href = url
    link.download = filename

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  } catch (error) {
    ToastSignal.value = {
      severity: ToastLevel.ERROR,
      message: terms.Messages.Download.failed('Excel'),
    }
  }
}

export const uploadExcel = async (request: Request, file: File) => {
  const formData = new FormData()
  formData.append(`${request.name}-${request.last_modified}-EP.xlsx`, file)

  await new Promise(_ => setTimeout(_, 1000))

  const [error] = await catchable(
    () => post(`/miki/requests/${request.id}/upload-ep`, formData),
    true,
    terms.Messages.Upload.failedExcel,
  )

  if (error) {
    ToastSignal.value = {
      severity: ToastLevel.ERROR,
      message: terms.Messages.Upload.failedExcel,
    }
    return [error]
  }

  const response = await getRequest(request.id)
  if (response.status === 'EP_ERROR') {
    return [terms.Messages.Errors.onEpError]
  }
  ToastSignal.value = {
    severity: ToastLevel.SUCCESS,
    message: terms.Messages.Upload.successExcel,
  }

  return [null]
}

export const getGedExport = async (request: Request) => {
  const [error, response] = await catchable(
    () => get<{status: string, progress: number, total: number}>(
      `/miki/requests/${request.id}/export/${request.ongoing_export_id}`,
    ),
    true,
  )
  await getRequest(request.id)

  if (error || !response) {
    // remove request from GedExportSignal
    GedExportSignal.value = {
      ...GedExportSignal.value,
      [request.id]: undefined,
    }
  }

  GedExportSignal.value = {
    ...GedExportSignal.value,
    [request.id]: {
      id: request.ongoing_export_id,
      ...response,
    },
  }
}

export const startGedExport = async (request: Request) => {
  const [postError, postResponse] = await catchable(
    () => post<{export_id: string}>(`/miki/requests/${request.id}/export`, {}),
    true,
  )

  if (postError || !postResponse?.export_id) return

  await getRequest(request.id)
  await getGedExport({ ...request, ongoing_export_id: postResponse.export_id })
}

export const getZipFiles = (request: Request) => async () => {
  const [error, response] = await catchable(
    () => get(`/miki/requests/${request.id}/report`, null, null, {
      responseType: 'json', // now expecting a JSON response
      headers: {
        'Content-Type': 'application/json', // adjust if necessary
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    }),
    true,
  )

  if (error || !response) {
    ToastSignal.value = {
      severity: ToastLevel.ERROR,
      message: terms.Messages.Download.failed('ZIP'),
    }
    return
  }

  // Assuming the JSON response has the structure:
  // { filename: "example.zip", body: "<base64-encoded-string>" }
  const { filename, body } = response

  // Decode the base64 string into binary data
  const byteCharacters = atob(body)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)

  // Create a Blob with the appropriate MIME type for zip files
  const blob = new Blob([byteArray], {
    type: 'application/zip',
  })

  const date = moment(request.last_modified).format('YYYYMMDDTHHmmss')
  const finalFilename = filename || `${request.name}_Rapports_Traitement_${date}.zip`

  const link = document.createElement('a')
  const url = URL.createObjectURL(blob)
  link.href = url
  link.download = finalFilename

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}
